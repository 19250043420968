<template>
  <div class="result">
    <v-cover
      class="result__cover"
      image="/img/portraits/portrait-result.jpg"
      :overlay="true"
      :contain="true"
    >
      {{ $t('result.title') }}
    </v-cover>
    <span v-if="concern" class="result__concern">
      {{ $t('result.concern') }}: <strong class="result__concern--strong">{{ concern }}</strong>
    </span>
    <v-slider>
      <v-product
        v-for="product in products"
        :key="product.id"
        class="v-slider__item result__product"
        :title="product.title"
        :description="product.description || ''"
        :image="product.bulyImage || ''"
        :careTag="product.bulyCareTag"
        :isSelected="selected.includes(product.bulyShopifyProductId)"
        @select="onSelect(product.bulyShopifyProductId)"
      />
      <div v-if="tip" class="result__officine">
        <span class="result__officine__title">{{ $t('result.tip') }}</span>
        <p class="result__officine__text">{{ tip.description }}</p>
      </div>
    </v-slider>
    <!-- <span class="result__concern">My concern: I would like to perfume my body</span>
    <v-slider>
      <v-product
        v-for="product in products"
        :key="product.id"
        class="result__product"
        :title="product.title"
        :image="product.bulyImage || ''"
      />
      <div class="result__officine">
        <span class="result__officine__title">Tip from the officine</span>
        <p class="result__officine__text">Conemquam andae parcim dolorpo ratiorest omnis porem facero vit imosanda quas estibeaturia incipit reruptasitat moloruptust eatae porendiciis volorum cum, aliqui omnia qui optio evel in rectotasi beates.</p>
      </div>
    </v-slider> -->

    <div class="result__cta gradient gradient--result">
      <v-button
        :isResult="true"
        class="result__cta__button"
        :href="addCart"
      >
        {{ $t('result.addCart') }}
      </v-button>

      <folding-button/>

      <pop-up :isOpen="recommendPopupOpen" @closePopup="recommendPopupOpen = false"/>

      <v-button
        :isResult="true"
        @click="recommendPopupOpen = true"
        class="result__cta__button"
      >
        {{ $t('result.recommend') }}
      </v-button>
      <v-button
        :isResult="true"
        class="result__cta__button"
        v-touch:tap="() => $router.push('start')"
      >
        {{ $t('result.shops') }}
      </v-button>
      <v-button
        :isResult="true"
        class="result__cta__button"
        v-touch:tap="newSearch"
      >
        {{ $t('result.newSearch') }}
      </v-button>
      <a class="result__link_cover" :href="$t('endPage.bulyUrl')">
        <v-cover
          v-if="$te('endPage.bulyImage.0')"
          class="result__cover"
          :image="$t('endPage.bulyImage.0.url')"
          :dark="true"
        >
          {{ $t('endPage.title') }}
        </v-cover>
      </a>
      <v-button
        :isResult="true"
        class="result__cta__button"
        v-touch:tap="() => $router.push('start')"
      >
        {{ $t('result.discover') }}
      </v-button>
    </div>
  </div>
</template>

<style lang="sass">
.result
  &__cover
    min-height: 480px

  &__abstract
    background-color: var(--light-grey)
    padding:
      top: 30px
      bottom: 30px
    text-align: center
    line-height: 1.3

  &__concern
    font-family: var(--font-family-label)
    font-size: var(--font-size-book)
    line-height: 23px
    letter-spacing: 1px
    margin-top: 52px
    margin-left: 40px
    display: inline-block
    font-weight: var(--font-weight-light)
    margin-bottom: 25px
    &--strong
      font-weight: var(--font-weight-bold)

  &__product
    display: flex
    justify-content: center
    flex-direction: column
    align-items: center
    width: 33.33%
    max-width: 350px
    flex:
      grow: 0
      shrink: 0
    margin:
      left: 35px
      right: 35px
    scroll-snap-align: start

  &__officine
    background-image: url(/img/illustrations/officine.jpg)
    background-size: cover
    background-position: center
    background-repeat: no-repeat
    box-sizing: border-box
    width: 380px
    min-width: 380px
    height: auto
    padding:
      top: 64px
      bottom: 64px
      left: 20px
      right: 20px
    color: var(--white)
    margin:
      left: 35px
      right: 35px
    text-align: center
    scroll-snap-align: start

    &__title
      display: inline-block
      font-style: normal
      font-weight: 700
      font-size: 18px
      line-height: 24px
      letter-spacing: 1.5px
      font-family: var(--font-family-label)
      text-align: center
      margin-bottom: 25px

    &__text
      font-style: normal
      font-weight: 400
      font-size: 25px
      line-height: 42px
      letter-spacing: 0.5px
      font-family: var(--font-family-text)
      text-align: center

  &__cta
    &__button
      margin: 30px
      &--unrolled
        .v-button__label
          background-color: var(--dark-green)
          color: var(--white)
        &::before
          border-color: var(--white)
        .result__cta__arrow
          transform: rotate(180deg)
    &__title
      width: 60%
    &__arrow
      transform: rotate(0deg)
      transition: transform 0.3s ease

  &__link_cover
    text-decoration: none

  @media screen and (max-width: 500px)
    &__products
      flex-direction: column

    &__product
      width: 90%
      margin-left: 5%
      margin-right: 5%

    &__officine
      min-width: 80%

    &__concern
      font-size: 9px

    &__cta__title
      width: 100%

.picto_line
  display: flex
  flex-direction: row
  margin: auto
  &:not(:first-of-type)
    margin-top: 10px
  &__text, &__picto
    width: 33.3%
  &__picto
    text-align: left
  &__text
    margin-left: auto

  @media screen and (max-width: 500px)
    &__text
      width: 85%
    &__picto
      width: 12.5%
</style>

<script>
import VCover from '@/components/VCover'
import VButton from '@/components/VButton'
import VProduct from '@/components/VProduct'
import VSlider from '@/components/VSlider'
import api from '@/api'

import PopUp from '../components/Results/PopUp.vue'
import FoldingButton from '../components/Results/FoldingButton.vue'

// const ITEMS_PER_CATEGORY = {
// Cat_PlantOil: 2,
// Cat_Essential: 1,
// Cat_OralCare: 1,
// Cat_Curiosities: 1,
// Cat_Powder: 1,
// Cat_Clay: 1,
// Cat_BodyCare: 1,
// Cat_FaceCare: 1,
// Cat_Perfume: 1
// }

export default {
  name: 'Result',

  components: {
    VProduct,
    VButton,
    VCover,
    VSlider,
    PopUp,
    FoldingButton
  },

  data () {
    return {
      recommendPopupOpen: false,
      selected: []
    }
  },

  computed: {
    products () {
      // const itemCategoriesCounter = { ...ITEMS_PER_CATEGORY }
      return api
        .filter(
          this.$store.state.answers,
          Object.values(this.$t('products')),
          [
            'bulyGender',
            'bulyClimate1',
            'bulyClimate2',
            'bulyAge',
            'BulyBodyPart',
            'bulySkinType',
            'bulySkinProblemCategory'
          ]
        )
        // .filter(product => itemCategoriesCounter[product.bulyCat]-- > 0)
    },

    concern () {
      const id = this.$store.state.answers.find(answer => answer.startsWith('N') || answer.startsWith('Q'))

      if (!id) { return }

      const concern = Object.values(this.$t(`answers.${id[0]}`)).find(answer => answer.bulyExternalid === id)

      return concern.title
    },

    tip () {
      return Object.values(this.$t('tips')).find(tip => this.$store.state.answers.includes(tip.bulyExternalid))
    },

    addCart () {
      return this.selected.reduce((previous, id, index) => {
        previous += id
        previous += index < this.selected.length - 1 ? ',' : ''
        return previous
      }, this.$t('store'))
    }
  },

  methods: {
    onSelect (id) {
      const index = this.selected.indexOf(id)
      if (index === -1) {
        this.selected.push(id)
      } else {
        this.selected.splice(index, 1)
      }
    },
    newSearch () {
      this.$store.commit('reinitAnswers')
      this.$ga.send(
        'skin_concierge',
        {
          skin_concierge_action: 'restart'
        }
      )
      this.$router.push('start')
    }
  }
}
</script>
