<template>
  <v-easy-dialog v-model="isOpen">
    <div class="recommend">
      <button class="recommend__close" @click="$emit('closePopup')"><img src="@/assets/icons/cross.svg" alt="x"></button>
      <span>Recommend skin concierge</span>
      <a href="#" class="recommend__link picto_line"><span class="picto_line__text recommend__link__text">e-mail </span> <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'picto_line__picto'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g clip-path="url(#clip0_427_691)"><path d="M19.743.258H.257v12.627h19.486V.258z" fill="currentColor"/><path class="svg--secondary_stroke" d="M.257.258l9.499 8.117L19.743.258"/></g><defs><clipPath id="clip0_427_691"><path fill="#fff" d="M0 0h20v13.143H0z"/></clipPath></defs></svg></a>
      <a href="#" class="recommend__link picto_line"><span class="picto_line__text recommend__link__text">instagram </span> <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'picto_line__picto'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M14.328.277H5.672A5.394 5.394 0 00.278 5.672v8.655a5.394 5.394 0 005.394 5.395h8.656a5.395 5.395 0 005.394-5.395V5.672A5.394 5.394 0 0014.328.277z" fill="currentColor"/><path class="svg--secondary__fill" d="M14.792 5.417a.833.833 0 100-1.667.833.833 0 000 1.667zM10 14.861a4.444 4.444 0 100-8.888 4.444 4.444 0 000 8.889z"/></svg></a>
      <a href="#" class="recommend__link picto_line"><span class="picto_line__text recommend__link__text">whatsapp </span> <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'picto_line__picto'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g clip-path="url(#clip0_427_694)"><path d="M8.723.42C4.449.95.997 4.28.436 8.41c-.375 2.76.502 5.32 2.154 7.234l-1.143 4.59 4.246-2.223a9.895 9.895 0 006.065.836c3.983-.698 7.164-3.836 7.775-7.705.983-6.224-4.4-11.518-10.81-10.723z" fill="currentColor"/><path class="svg--secondary__fill" d="M13.016 10.65c.34-.35.866-.404 1.175-.123l1.47 1.337c.31.281.286.792-.053 1.141l-.615.632-1.2 1.234c-.34.35-.865.404-1.175.123l-2.03-1.846-4.336-3.942-1.894-1.721c-.386-.35-.355-.986.067-1.42L6.09 4.353c.34-.349.866-.404 1.176-.123l1.47 1.337c.31.281.285.792-.054 1.142-.34.348-.364.86-.054 1.142l3.214 2.921c.31.282.836.227 1.175-.122h-.001z"/></g><defs><clipPath id="clip0_427_694"><path d="M0 0h20v20.571H0z"/></clipPath></defs></svg></a>
      <a href="#" class="recommend__link picto_line"><span class="picto_line__text recommend__link__text">copy link </span> <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'picto_line__picto'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M13.97 10.864l2.76-2.76a3.67 3.67 0 000-5.173 3.67 3.67 0 00-5.175 0l-2.76 2.76M5.691 8.793l-2.759 2.76a3.67 3.67 0 000 5.173 3.67 3.67 0 005.174 0l2.76-2.76M12.59 7.07L7.07 12.59" stroke="currentColor" stroke-miterlimit="10"/></svg></a>
    </div>

  </v-easy-dialog>
</template>

<style lang="sass">
.recommend
  padding: 55px
  padding-left: 335px
  background:
    color: var(--light-grey)
    image: url(/img/portraits/portrait-recommend.png)
    repeat: no-repeat
    size: contain
  font-family: var(--font-family-label)
  font-style: normal
  font-weight: 400
  font-size: 18px
  line-height: 24px
  text:
    align: center
  display: flex
  flex-direction: column
  position: relative
  &__close
    position: absolute
    top: 16px
    right: 16px
    background: none
    border: none
    cursor: pointer
  &__link
    width: 100%
    align-items: center
    color: var(--black)
    opacity: 0.5
    transition: opacity 0.3s ease
    will-change: opacity
    text-decoration: none
    margin-top: 20px !important
    &:visited
      color: var(--black)
    &:hover
      opacity: 1
    .svg--secondary__fill
      fill: var(--light-grey)
    .svg--secondary_stroke
      stroke: var(--light-grey)

    @media screen and (min-width: 500px)
      &__text
        width: 35% !important

  @media screen and (max-width: 500px)
    font-size: var(--font-size-tiny)
    padding-left: initial
    padding: 20px 40px
    background-position: -120px

.v-easy-dialog--backdrop-btn
  background-color: transparent
.v-easy-dialog--content-container
  width: auto!important
</style>

<script>
import VEasyDialog from 'v-easy-dialog'

export default {
  components: {
    VEasyDialog
  },
  emits: ['closePopup'],
  props: {
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  methods: {}
}
</script>
