<template>
  <component
    :is="href === 'false' ? 'div' : 'a'"
    :href="href"
    target="_blank"
    class="v-button"
    :class="{
      'v-button--light': light,
      'v-button--secondary': secondary,
      'v-button--result': isResult,
      'is-force-hover': forceHover,
    }"
    @click="$emit('click')"
  >
    <div
      class="v-button__label"
      :class="['v-button__label--' + color]"
    >
      <slot />
    </div>
  </component>
</template>

<script>
export default {
  props: {
    href: {
      type: String,
      default: 'false'
    },
    light: {
      type: Boolean,
      default: false
    },
    secondary: {
      type: Boolean,
      default: false
    },
    isResult: {
      type: Boolean,
      default: false
    },
    forceHover: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: 'white'
    }
  }
}
</script>

<style lang="sass">
.v-button
  border: 1px solid var(--black)
  position: relative
  cursor: pointer
  display: block
  text-decoration: none
  font:
    family: var(--font-family-label)
    size: var(--font-size-book)
    weight: var(--font-weight-light)
  text:
    align: center
    transform: uppercase
  letter-spacing: var(--font-spacing-label)

  &::before
    content: ''
    width: calc(100% - 8px)
    height: calc(100% - 8px)
    top: 2px
    left: 2px
    border: 2px solid var(--black)
    position: absolute
    pointer-events: none

  &, &::before
    transition: .4s

  &:active
    .v-button__label
      background-color: var(--black)
      color: var(--white)

    &::before
      border-color: var(--white)

    &, &::before
      transition: 0s

  &__label
    width: 100%
    padding: 20px 40px
    box-sizing: border-box
    line-height: var(--font-height-label)
    background-color: var(--white)
    transition: background-color 0.4s ease

    &--semi-grey
      background-color: var(--white-grey)
      &:hover
        background-color: var(--white)
    &--light-alpha
      background-color: var(--white-faded)
      &:hover
        background-color: var(--white)
    &--transparent
      background-color: transparent
      &:hover
        background-color: transparent

  &--result
    color: var(--black)
    &:active
      color: var(--black)

      &::before
        border-color: var(--black)

  &--secondary
    opacity: .3
    border: none
    transition: opacity .3s ease
    will-change: opacity
    &::before
      display: none
    &:hover
      opacity: 1
  &--result
    &:hover, &.is-force-hover
      color: var(--white)
      .v-button__label
        background-color: var(--dark-green) !important

      &::before
        border-color: var(--white)

  @media screen and (max-width: 500px)
    font-size: var(--font-size-tiny)
</style>
