const resultAbstractParts = {
  A1: 'woman',
  A2: 'man',
  A3: '',
  B1: 'rainy',
  B2: 'mountainous',
  B3: 'dry',
  B4: 'tropical',
  B5: 'temperate',
  B6: 'sunny',
  B7: 'urban',
  C1: 'polluted',
  C2: 'unpolluted',
  E1: 'face',
  E2: 'body',
  E3: 'hands & feet',
  E4: 'hair'
}

export default {
  localeName: 'English',
  resultForm: {
    confirm: 'confirm'
  },
  result: {
    title: 'The Officine recommends',
    cta: 'Mirror, mirror on the wall, what are the best results of all?',
    concern: 'My concern',
    select: 'Select',
    unselect: 'Unselect',
    addCart: 'Add selection to cart',
    question: 'A question ?',
    recommend: 'Recommend to a friend',
    shops: 'Our boutiques',
    newSearch: 'Start a new one',
    discover: 'Discover',
    skip: 'Skip',
    showMore: 'Show more',
    tip: 'A witty advice from the Officine'
  },
  loading: 'Loading',
  // HARD CODED
  questions: {
    extra: {
      id: 'extra',
      bulyImage: '/img/portraits/portrait-skin.jpg',
      title: 'What is your skin tone?',
      analyticSlug: '0-what-is-your-skin-tone'
    }
  },
  diagnosis: {
    onTheWay: 'Our diagnosis is on the way',
    skip: 'Skip'
  },
  care_tag: {
    Care_Morning: 'Morning care',
    Care_Regular: 'Regular care',
    Care_Occasional: 'Occasional care',
    Care_Evening: 'Evening care',
    Care_Daily: 'Daily care',
    Care_Morning_Evening: 'Morning and Evening care'
  },
  store: 'https://www.buly1803.com/en/?concierge=',
  klaviyoId: 'VHmXJV',
  resultAbstract: (ctx) => (
    `You are a ${
      resultAbstractParts[ctx.named('gender')]
    } who lives in a ${
      resultAbstractParts[ctx.named('climate1')]
    } ${
      resultAbstractParts[ctx.named('climate2')]
    } environment, concerned about your ${
      resultAbstractParts[ctx.named('concern')]
    }`
  )
}
