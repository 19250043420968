<template>
  <div class="start">
    <v-cover
      :image="coverImage"
    >
      {{ $t('startPage.titre') }}
    </v-cover>

    <div
      v-for="(item, index) in $t('startPage.bulyDescription')"
      :key="index"
      class="start__content wrapper"
    >
      {{ item.description }}
    </div>

    <div class="start__content wrapper">
      <v-button class="start__button" color="semi-grey" v-touch:tap="() => this.start()">
        {{ $t('startPage.bulyCta') }}
      </v-button>
    </div>
  </div>
</template>

<style lang="sass">
.start
  padding-bottom: 30px
  background-color: var(--light-grey)
  &__content
    line-height: var(--font-height-text)
    margin:
      top: 30px
      bottom: 30px
    text-align: center
  &__button
    max-width: 570px
    margin-left: auto
    margin-right: auto
    .v-button__label
      padding: 10px
      line-height: 24px
      letter-spacing: 1px
      font-size: var(--font-size-book)
      font-weight: 100
</style>

<script>
import VCover from '@/components/VCover.vue'
import VButton from '@/components/VButton.vue'
import coverImage from '@/assets/images/portrait start.jpg'

export default {
  name: 'Start',

  created () {
    this.coverImage = coverImage
  },

  methods: {
    start () {
      this.$audio.play('/sounds/ding.mp3')
      this.$router.push({ name: 'question', params: { id: 'A' } })
      this.$ga.send(
        'skin_concierge',
        {
          skin_concierge_action: 'start'
        }
      )
    }
  },

  components: {
    VCover,
    VButton
  }
}
</script>
