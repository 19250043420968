import Vue from 'vue'
import VueI18n from 'vue-i18n'
import api from '@/api'
import en from './locales/en'
import fr from './locales/fr'
// import jp from './locales/jp'

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: 'fr',
  fallbackLocale: 'fr',
  messages: {
    en,
    fr
    // jp
  }
})

i18n.loadedLocales = []
i18n.loadLocale = async function (locale = this.locale) {
  this.locale = locale

  if (!this.loadedLocales.includes(locale)) {
    // load questyion data here
    const data = await api.fetch(locale)
    this.mergeLocaleMessage(locale, data)
    this.loadedLocales.push(locale)
  }
}

export default i18n
