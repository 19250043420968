<template>
  <div class="mail_form">
    <v-cover
      class="mail_form__cover"
      :image="require('@/assets/images/portrait-diagnosis.png')"
      />
    <v-frame class="mail_form__frame">
      <v-cover class="diagnosis">
        {{ $t('diagnosis.onTheWay') }}
      </v-cover>
    </v-frame>
    <!-- can be v-ifed if the result calculation in incomplete -->
    <div class="mail_form__fields" ref="formContainer" v-if="true">
      <div :class="'klaviyo-form-' + $t('klaviyoId')"></div>
      <v-button @click="() => $router.push('result')" color="transparent" :secondary="true">{{ $t('diagnosis.skip') }}</v-button>
    </div>
  </div>
</template>

<style lang="sass">
.mail_form
  &__cover, &__frame, &__fields
    height: calc(var(--vh, 1vh) * 100)
    box-sizing: border-box
  &__fields
    background-color: var(--light-grey)
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
  .v-cover__image
    background-color: rgba(0, 0, 0, 0.65)
    background-size: 90%
  @media (max-width: 500px)
    .v-cover__image
      background-repeat: no-repeat
      background-size: 249%
      background-position: center center
      background-color: rgba(0, 0, 0, 0.65)
.diagnosis
  color: var(--red)
  font-size: var(--font-size-large)
  width: 55.1vw
  @media (max-width: 500px)
    width: 81vw
.klaviyo-form
  width: 70%
  margin: 0 auto
  .kl-private-reset-css-Xuajs1
    justify-content: center
  p
    font-size: var(--font-size-book) !important
    color: var(--black) !important
    font-weight: var(--font-weight-regular) !important
    margin: auto
    margin-bottom: 6px !important
    font-family: var(--font-family-text) !important
    line-height: 24px !important
  input, input::placeholder
    height: none !important
    background: none !important
    font-weight: var(--font-weight-regular) !important
    font-family: var(--font-family-label) !important
    font-size: var(--font-size-book) !important
    color: var(--dimgrey) !important
    border: none !important
    border-bottom: 2px solid var(--black) !important
    padding: 25px 0 !important
    margin-bottom: 35px !important
    width: 100% !important
  button
    display: inline-block !important
    margin: 50px auto 0 auto !important
    font-weight: var(--font-weight-regular) !important
    font-family: var(--font-family-label) !important
    font-size: var(--font-size-book) !important
    background-color: rgba(255, 255, 255, 0.5) !important
    border: 1px solid black !important
    position: relative !important
    padding: 20px 50px !important
    color: var(--black) !important
    &::before
      border: 2px solid black
      content: ''
      pointer-events: none
      position: absolute
      top: 3px
      left: 3px
      z-index: 5
      background: none
      width: calc(100% - 10px)
      height: calc(100% - 10px)
  @media screen and (max-width: 500px)
    width: 94%
    input, input::placeholder, button
      font-size: 11px !important
    p
      margin-bottom: 0 !important
    input, input::placeholder, button
      margin-bottom: 20px !important
    button
      margin: 30px auto 0 auto !important
      padding: 10px 40px !important

</style>

<script>
import VCover from '@/components/VCover'
import VFrame from '@/components/VFrame'
import VButton from '@/components/VButton'

import gsap from 'gsap'

export default {
  created () {
    this.hasSeenFistPanel = false
    this.hasSeenSecondPanel = false
    this.scroll = { y: 0 }
  },
  components: {
    VCover,
    VFrame,
    VButton
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)

    setTimeout(async () => {
      if (!this.hasSeenFistPanel) await this.autoScroll(1)
      if (!this.hasSeenSecondPanel) {
        setTimeout(() => {
          this.autoScroll(2)
        }, 3000)
      }
    }, 3000)

    // wait for klaviyo form generation
    setTimeout(() => {
      const form = this.$refs.formContainer.firstChild.firstChild.firstChild
      form.querySelector('button').addEventListener('click', () => {
        setTimeout(() => { this.$router.push('result') }, 1000)
      })
    }, 450)
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll () {
      const scrollTop = window.scrollY
      this.scroll.y = scrollTop
      if (scrollTop >= window.innerHeight) {
        this.hasSeenFistPanel = true
      }
      if (scrollTop >= window.innerHeight * 2) {
        this.hasSeenSecondPanel = true
      }
    },
    autoScroll (index) {
      return new Promise(resolve => {
        gsap.to(this.scroll, {
          y: window.innerHeight * index,
          duration: 2,
          ease: 'power2.inOut',
          onUpdate: () => {
            window.scrollTo(0, this.scroll.y)
          },
          onComplete: () => {
            resolve()
          }
        })
      })
    }
  }
}
</script>
