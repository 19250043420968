import './stylesheets/main.sass'
import Vue from 'vue'
import Vue2TouchEvents from 'vue2-touch-events'
import VueAudio from './plugins/audio'
import Analytics from './plugins/Analytics'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import i18n from './i18n'
import api from './api'

window.api = api

Vue.use(Vue2TouchEvents)
Vue.use(VueAudio)
Vue.use(Analytics)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
