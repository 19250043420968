const resultAbstractParts = {
  A1: 'une femme',
  A2: 'un homme',
  A3: '',
  B1: 'humide',
  B2: 'montagneux',
  B3: 'sec',
  B4: 'tropical',
  B5: 'temperé',
  B6: 'très ensoleillé',
  B7: 'urbain',
  C1: 'pollué',
  C2: 'non pollué',
  E1: 'son visage',
  E2: 'son corps',
  E3: 'ses mains et pieds',
  E4: 'ses cheveux',

  concernedAbout: {
    A1: 'soucieuse de',
    A2: 'soucieux de'
  }
}

export default {
  localeName: 'Français',
  resultForm: {
    confirm: 'confirmer'
  },
  result: {
    title: 'Les recommandations de l’Officine',
    cta: 'Miroir, mon gentil miroir, quels sont mes résultats ?',
    concern: 'Ma préoccupation',
    select: 'Sélectionner',
    unselect: 'Retirer',
    addCart: 'Ajouter la sélection au panier',
    newSearch: 'Nouvelle recherche',
    question: 'Une question ?',
    recommend: 'Recommander à un ami',
    shops: 'Nos boutiques',
    discover: 'Discover',
    skip: 'Ignorer',
    showMore: 'Voir plus',
    tip: 'Le conseil avisé de l\'Officine'
  },
  loading: 'Chargement',
  questions: {
    extra: {
      id: 'extra',
      bulyImage: '/img/portraits/portrait-skin.jpg',
      title: 'Quelle est la teinte de votre peau ?',
      analyticSlug: '0-what-is-your-skin-tone'
    }
  },
  diagnosis: {
    onTheWay: 'Notre diagnostic est en cours',
    skip: 'Ignorer'
  },
  care_tag: {
    Care_Morning: 'Soin du matin',
    Care_Regular: 'Soin régulier',
    Care_Occasional: 'Soin occasionnel',
    Care_Evening: 'Soin du soir',
    Care_Daily: 'Soin quotidien',
    Care_Morning_Evening: 'Soin du matin et du soir'
  },
  store: 'https://www.buly1803.com/fr/?concierge=',
  klaviyoId: 'WGVhR8',
  resultAbstract: (ctx) => (
    `Vous êtes ${resultAbstractParts[ctx.named('gender')]
    } qui vit dans un environement ${resultAbstractParts[ctx.named('climate1')]
    } et ${resultAbstractParts[ctx.named('climate2')]
    }, ${resultAbstractParts.concernedAbout[ctx.named('gender')]
    } ${resultAbstractParts[ctx.named('concern')]
    }`
  )
}
