export default {
  install (Vue) {
    let initialize
    const initialization = new Promise((resolve) => { initialize = resolve })

    const audio = {
      context: null,
      buffers: {},

      load (...urls) {
        return Promise.all(urls.map((url) => {
          const load = fetch(url).then((response) => (
            response.arrayBuffer()
          ))

          this.buffers[url] = Promise.all([
            load,
            initialization
          ]).then(([buffer]) => new Promise((resolve, reject) => {
            this.context.decodeAudioData(buffer, resolve, reject)
          }))

          return load
        }))
      },

      play (url, { loop = false } = {}) {
        url in this.buffers || this.load(url)
        this.buffers[url].then((buffer) => {
          const source = this.context.createBufferSource()
          source.buffer = buffer
          source.loop = loop
          source.onended = () => source.disconnect()
          source.connect(this.context.destination)
          source.start()
        })
      }
    }

    const interaction = () => {
      audio.context = new (window.AudioContext || window.webkitAudioContext)()
      initialize()

      removeEventListener('touchstart', interaction)
      removeEventListener('mousedown', interaction)
    }

    addEventListener('touchstart', interaction)
    addEventListener('mousedown', interaction)

    Vue.prototype.$audio = audio
  }
}
