<template>
  <div class="language">

  </div>
</template>

<style lang="sass">
.language
  background-color: yellow
</style>

<script>
export default {
  name: 'Language'
}
</script>
