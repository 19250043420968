<template>
  <div
    class="v-cover"
    :class="{
      'v-cover--overlay': overlay,
      'v-cover--contain': contain,
      'v-cover--dark': dark,
    }"
  >
    <div
      v-if="image"
      class="v-cover__image"
      :style="{ backgroundImage: `url('${image}')` }"
      ref="image"
    ></div>
    <p class="wrapper v-cover__wrapper v-cover__text">
      <slot></slot>
    </p>
  </div>
</template>

<style lang="sass">
.v-cover
  overflow: hidden
  padding:
    top: 50px
    bottom: 60px
  color: var(--white)
  display: flex
  align-items: center
  justify-content: center
  position: relative
  overflow-wrap: break-word
  font:
    family: var(--font-family-title)
    size: var(--font-size-huge)
  text:
    align: center
    transform: uppercase
    decoration: none

  &__image
    width: 100%
    height: 130%
    top: 50%
    left: 0
    position: absolute
    z-index: 0
    transform: translateY(-50%)
    background:
      repeat: no-repeat
      size: cover
      position: center center

  &__text
    z-index: 1

  &__wrapper
    word-break: break-word

  &--overlay &__image::before
    content: ''
    background-color: rgba(0, 0, 0, 0.6)
    width: 100%
    height: 100%
    top: 0
    left: 0
    position: absolute

  &--contain &__image
    background-size: contain

  &--dark
    color: var(--black)

  @media screen and (max-width: 500px)
    &--contain &__image
      width: 150%
      left: -20%
</style>

<script>
export default {
  name: 'VCover',

  props: {
    image: {
      type: String,
      default: ''
    },
    overlay: {
      type: Boolean,
      default: false
    },
    contain: {
      type: Boolean,
      default: false
    },
    dark: {
      type: Boolean,
      default: false
    }
  },

  watch: {
    image: {
      imediate: true,
      handler (image) {
        cancelAnimationFrame(this.frameRequest)
        image && this.updateParallax()
      }
    }
  },

  created () {
    this.updateParallax = this.updateParallax.bind(this)
    this.onResize = this.onResize.bind(this)
    this.rect = {}
    this.frameRequest = null
  },

  mounted () {
    addEventListener('resize', this.onResize)
  },

  beforeDestroy () {
    cancelAnimationFrame(this.frameRequest)
    removeEventListener('resize', this.onResize)
  },

  methods: {
    updateParallax () {
      const rect = this.$el.getBoundingClientRect()

      if (rect.top !== this.rect.top || rect.height !== this.rect.height) {
        const imageRect = this.$refs.image.getBoundingClientRect()
        const viewport = window.innerHeight
        const offset = (imageRect.height - rect.height) / imageRect.height * 100

        this.rect = rect
        this.$refs.image.style.transform = `translateY(${offset * (
          (1 - Math.min(Math.max((rect.top + rect.height) / rect.height, 0), 1)) -
          Math.min(Math.max(rect.top / viewport, 0), 1)
        )}%)`
      }

      this.frameRequest = requestAnimationFrame(this.updateParallax)
    },

    onResize () {
      this.rect = {}
    }
  }
}
</script>
