<template>
  <div class="v-frame">
    <div class="v-frame__container">
      <slot></slot>
    </div>
  </div>
</template>

<style lang="sass">
.v-frame
  padding: 24px
  box-sizing: border-box

  &__container
    width: 100%
    height: 100%
    padding: 30px
    border: 1px solid var(--black)
    box-sizing: border-box
    position: relative
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center

    &::before, &::after
      content: ''
      display: block
      position: absolute
      z-index: -1

    &::before
      width: calc(100% - 12px)
      height: calc(100% - 12px)
      top: 3px
      left: 3px
      border: 3px solid var(--black)

    // &::after
    //   width: calc(100% - 34px)
    //   height: calc(100% - 34px)
    //   top: 16px
    //   left: 16px
    //   border: 1px solid var(--black)
</style>

<script>
export default {
  name: 'VFrame'
}
</script>
