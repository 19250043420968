import Vue from 'vue'
import VueRouter from 'vue-router'
import Introduction from '../views/Introduction.vue'
import Language from '../views/Language.vue'
import Start from '../views/Start.vue'
import Question from '../views/Question.vue'
import Result from '../views/Result.vue'
import ResultSplash from '../views/ResultSplash.vue'
import MailForm from '../views/MailForm.vue'

// Ignore errors about consecutive redirects
const push = VueRouter.prototype.push
VueRouter.prototype.push = function (location, onResolve, onReject) {
  return onResolve || onReject
    ? push.call(this, location, onResolve, onReject)
    : push.call(this, location).catch(error => error)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'introduction',
    component: Introduction
  },
  {
    path: '/language',
    name: 'language',
    component: Language
  },
  {
    path: '/start',
    name: 'start',
    component: Start
  },
  {
    path: '/question/:id',
    name: 'question',
    component: Question
  },
  {
    path: '/result-splash',
    name: 'result-splash',
    component: ResultSplash
  },
  {
    path: '/mail-form',
    name: 'mail-form',
    component: MailForm
  },
  {
    path: '/result',
    name: 'result',
    component: Result
  }
]

export default new VueRouter({
  routes
})
