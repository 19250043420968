<template>
  <div class="question">
    <v-cover
      class="question__cover"
      :image="question.bulyImage"
      :overlay="true"
    >
      {{ question.title || 'Question' }}
    </v-cover>

    <p
      class="question__description wrapper"
      v-if="question.description"
    >
      {{ question.description }}
    </p>

    <div
      class="question__answers"
      :class="answersClassName"
    >
      <v-option
        class="question__answer"
        :class="{ 'gradient__color': !question.bulyNoTextAnswer }"
        v-for="answer in answers"
        :image="answer.bulyImage"
        :key="answer.id"
        :no-text="question.bulyNoTextAnswer || question.id === 'extra'"
        :full-image="question.bulyIsimagefull"
        v-touch:tap="() => selectAnswer(answer)"
      >
        {{ answer.title }}
      </v-option>
    </div>
  </div>
</template>

<style lang="sass">
.question
  display: flex
  flex-direction: column
  min-height: 100vh
  min-height: calc(var(--vh, 1vh) * 100)

  &__cover
    min-height: 280px

  &__description
    line-height: var(--font-height-text)
    text-align: center
    padding:
      top: 30px
      bottom: 30px
    max-width: none
    background-color: var(--light-grey)

  &__answers
    flex-grow: 1
    display: flex
    flex-direction: row
    flex-wrap: wrap

  &__answer
    flex-grow: 1
</style>

<script>
import VCover from '@/components/VCover.vue'
import VOption from '@/components/VOption.vue'
import api from '@/api'

export default {
  name: 'Question',

  mounted () {
    if (
      !this.$route.params.id !== 'A' &&
      !this.$store.state.answers.some(id => id[0] === 'A')
    ) {
      this.$router.push({ name: 'question', params: { id: 'A' } })
      return
    }

    // return next question ID
    // or 'EXTRA' instead of C
    const id = this.nextQuestionID()

    if (id) {
      this.preloadPromise = Promise.all([
        api.preloadQuestionImage(this.$t(`questions.${id}`)),
        api.preloadAnswerImages(this.$t(`answers.${id}`))
      ])
    }
  },

  components: {
    VCover,
    VOption
  },

  computed: {
    question () {
      return this.$t(`questions.${this.$route.params.id}`)
    },

    answers () {
      if (this.$route.params.id === 'extra') {
        // shitty loop to match files order
        // usability null. Should have rename files
        // fun maximum. Long time no toture arrays
        const answers = []
        const rows = 9
        const columns = 3
        for (let i = 1; i < rows + 1; i++) {
          for (let j = 0; j < columns; j++) {
            const index = rows * j + i
            answers.push({
              id: `es${index}`,
              title: '',
              bulyImage: `/img/skins/${index}.jpg`,
              analyticSlug: `skin-0${index}`
            })
          }
        }

        return answers
      }

      return this.answersFor(this.$route.params.id)
    },

    answersClassName () {
      const className = []

      if (!this.question.bulyNoTextAnswer && this.question.bulyGradient) {
        className.push(
          `gradient gradient--${this.question.bulyGradient.replace(/_/g, '-')}`
        )
      }

      return className.join(' ')
    }
  },

  methods: {
    async selectAnswer (answer) {
      answer.bulyExternalid && this.$store.commit('selectAnswer', answer.bulyExternalid)
      const id = this.nextQuestionID()

      this.$ga.send(
        'skin_concierge',
        {
          skin_concierge_step: this.question.analyticSlug,
          skin_concierge_choice: answer.analyticSlug
        }
      )
      if (id) {
        await this.preloadPromise
        this.$router.push({
          name: 'question',
          params: { id }
        })
      } else {
        this.$router.push({ name: 'result-splash' })
      }
    },

    nextQuestionID () {
      // UTF-16 OF THE CARACTER
      // SO ONLY THE FIRST ONE
      // BETWEEN 65 AND 98
      let id = this.$route.params.id.charCodeAt(0) + 1

      switch (this.$route.params.id) {
        // THE QUESTION BEFORE SKIN COLOR
        case 'F':
          return 'extra'
        // THE QUESTION AFTER SKIN COLOR
        case 'extra':
          id = 'G'.charCodeAt(0)
          break
      }

      // WE CHECK IN TRANSLATION IF THERE'S A CORRESPONDANCE
      // BECAUSE DATA ARE STORED IN TRANSLATION
      // IF THERE IS NOT, WE CHECK NEXT INDICE
      while (
        (!this.$te(`questions.${String.fromCharCode(id)}`) ||
        !this.$te(`answers.${String.fromCharCode(id)}`) ||
        !this.answersFor(String.fromCharCode(id)).length) &&
        id < 'z'.charCodeAt(0)
      ) {
        id++
      }

      id = String.fromCharCode(id)

      // THEN WE RETURN ID FOUNDED
      if (this.$te(`questions.${id}`) && this.$te(`answers.${id}`)) {
        return id
      }

      return null
    },

    answersFor (id) {
      // console.log(Object.values(this.$t(`answers.${id}`)))
      return api.filter(
        this.$store.state.answers,
        Object.values(this.$t(`answers.${id}`)),
        ['bulyGenderCondition', 'bulyConcern', 'bulySkinProblemCategory1', 'bulySkinProblemCategory2', 'bulySkinProblemCategory3']
        // ['bulyCondition', 'bulyGenderCondition']
      )
    }
  }
}
</script>
