export default {
  install (Vue) {
    const analytics = {}
    // change at cookie bar implementation
    analytics.send = (eventName, eventData) => {
      window.dataLayer.push({
        event: eventName,
        ...eventData
      })
    }

    Vue.prototype.$ga = analytics
  }
}
