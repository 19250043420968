<template>
  <component
    :is="href === '' ? 'div' : 'a'"
    :href="href"
    target="_blank"
    class="v-option"
    :class="{
      'v-option--selected': selected,
      'v-option--no-text': noText,
      'v-option--full-image': fullImage
    }"
    v-touch:tap="() => $audio.play('/sounds/click.mp3')"
  >
    <div
      class="v-option__image"
      :style="{ backgroundImage: `url('${image}')` }"
    ></div>
    <span class="v-option__text" v-if="!noText">
      <slot/>
    </span>
  </component>
</template>

<style lang="sass">
.v-option
  width: 100%
  cursor: pointer
  position: relative
  box-sizing: border-box
  text-decoration: none
  color black
  display: flex
  flex-direction: column
  justify-content: center
  font:
    family: var(--font-family-label)
    size: var(--font-size-small)
    weight: var(--font-weight-bold)
  text:
    align: center
    transform: uppercase
  color: var(--white)
  flex:
    grow: 0
    shrink: 0

  &__image
    background:
      size: contain
      repeat: no-repeat
      position: 20% center
    width: 100%
    height: 100%
    top: 0
    left: 0
    position: absolute
    mix-blend-mode: multiply

  &__text
    padding: 30px 30px calc(30px - .45em) 30px
    line-height: 2
    z-index: 2
    position: relative

  &::before
    content: ''
    width: 100%
    height: 100%
    top: 0
    left: 0
    z-index: 2
    line-height: 1.6
    border-top: 2px solid var(--white)
    position: absolute

  &:last-of-type::before
    border-bottom: 2px solid var(--white)

  &::after
    content: ''
    background-color: var(--black)
    width: 100%
    height: 100%
    top: 0
    left: 0
    opacity: 0
    z-index: 1
    position: absolute
    transition: .4s

  &:active::after
    opacity: 1
    transition: 0s

  &--selected::after
    opacity: .5

  &--no-text
    height: 70px
    width: 33.33%
    flex:
      shrink: 0
      grow: 0

    &:nth-of-type(3n + 1), &:nth-of-type(3n + 2)
      &::before
        border-right: 2px solid var(--white)

    @media screen and (max-width: 500px)
      height: 0
      padding-top: 33.33%

  &--no-text &__image, &--full-image &__image
    background:
      size: cover
      position: center center

  &--no-text &__image
    mix-blend-mode: normal

  @media screen and (max-width: 500px)
    &__image
      background:
        size: cover
        position: center center
</style>

<script>
export default {
  name: 'VOption',

  props: {
    image: {
      type: String,
      required: false,
      default: ''
    },
    selected: {
      type: Boolean,
      required: false,
      default: false
    },
    noText: {
      type: Boolean,
      required: false,
      default: false
    },
    fullImage: {
      type: Boolean,
      required: false,
      default: false
    },
    href: {
      type: String,
      default: ''
    }
  }
}
</script>
