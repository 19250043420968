<template>
  <div class="v-product">
    <img
      class="v-product__image"
      :src="image || 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEUAAACnej3aAAAAAXRSTlMAQObYZgAAAApJREFUeNpjZgAAAAgABPsKmp8AAAAASUVORK5CYII='"
    >
    <p v-if="careTag" class="v-product__category">
      {{ $t(`care_tag.${careTag}`) }}
    </p>
    <p class="v-product__title">
      {{ title | truncate(false, 50) }}
    </p>
    <p class="v-product__desription" :class="{ 'v-product__desription--unfolded': folded }">
      {{ description | truncate(folded, 68) }}
    </p>

    <button v-if="description.length > 68" @click="folded = !folded" class="v-product__show-more">
      <img
        class="v-product__show-more__arrow"
        :class="{
          'v-product__show-more__arrow--folded': folded
        }"
        src="@/assets/icons/arrow-down.svg"
        alt="v">
      {{ $t('result.showMore') }}
    </button>

    <v-button class="v-product__button" :forceHover="isSelected" :isResult="true" @click="$emit('select')">
      {{ isSelected ? $t('result.unselect') : $t('result.select') }}
    </v-button>
  </div>
</template>

<script>
import VButton from '@/components/VButton'

export default {
  components: {
    VButton
  },

  data () {
    return {
      folded: false
    }
  },

  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    image: {
      type: String,
      required: true
    },
    link: {
      type: String,
      default: ''
    },
    careTag: {
      type: String,
      default: ''
    },
    isSelected: {
      type: Boolean,
      default: false
    }
    // shopifyProductId: {
    //   type: String,
    //   required: true
    // }
  },

  filters: {
    truncate: function (value, folded, threshold) {
      if (!value) return ''
      if (folded || value.length <= threshold) return value
      var truncated = value.substr(0, 68)
      return truncated + '...'
    }
  }
}
</script>

<style lang="sass">
.v-product
  &__image
    width: 75%
    height: auto
    display: block

  &__title
    margin: 6px 0 18px 0
    text:
      transform: uppercase
      align: center
    font:
      family: var(--font-family-title)
      size: var(--font-size-medium)

    font-style: normal
    font-weight: 400
    font-size: 34px
    line-height: 38px
    letter-spacing: 1.5px
    -webkit-line-clamp: 2
    display: -webkit-box
    -webkit-box-orient: vertical
    overflow: hidden

  &__button
    margin: 0 auto
    margin-top: auto
    width: 100%
    display: block

  &__category
    font-family: var(--font-family-subtitle)
    font-style: normal
    font-weight: 400
    font-size: 13px
    line-height: 14px
    margin-top: 7px

  &__desription
    font-family: var(--font-family-text)
    font-style: normal
    font-weight: 400
    font-size: 18px
    line-height: 24px
    letter-spacing: 1px
    margin-bottom: 30px
    max-height: 72px
    overflow: hidden
    transition: max-height .3s ease-in-out
    -webkit-line-clamp: 2
    display: -webkit-box
    -webkit-box-orient: vertical
    &--unfolded
      -webkit-line-clamp: none
      display: block
      -webkit-box-orient: initial
      max-height: 500px
    text-align: center

  &__show-more
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    font-family: var(--font-family-label)
    font-style: normal
    font-weight: 400
    font-size: 9px
    line-height: 10px
    letter-spacing: 5px
    margin-bottom: 30px
    background: none
    border: none
    cursor: pointer

    &__arrow
      margin-bottom: 12px
      transition: .4s
      &--folded
        transform: rotate(180deg)
    img
      margin-bottom: 12px

  @media screen and (max-width: 500px)
    &__image
      width: auto
      max-height: 36vh
    &__description
      font-size: 17px
      margin-bottom: 17px
    &__show-more
      margin-bottom: 24px
</style>
