<template>
  <div class="introduction">
    <div class="introduction__splash">
      <img
        class="introduction__image"
        :src="require('@/assets/images/portrait_intro_blanc.png')"
      />

      <v-frame
        class="introduction__frame"
        ref="frame"
      >
        <img class="introduction__logo" :src="require('@/assets/images/LOGO-OUB.svg')" alt="Officine Universelle Buly">
        <p class="introduction__title">
          <span class="introduction__word">Skin</span><br>
          <span class="introduction__word">concierge</span>
        </p>
        <div class="introduction__subtitle">
          <p class="introduction__subtitle-top">
            <span class="introduction__word">The</span> <span class="introduction__word">natural</span><br>
          </p>
          <p class="introduction__subtitle-bottom">
            <span class="introduction__word">beauty</span> <span class="introduction__word">expert</span>
          </p>
        </div>
        <p class="introduction__cta" ref="cta">
          {{ $t(touch
            ? 'homePage.BulyCtaMobile'
            : 'homePage.bulyCta'
          ) }}
        </p>
        <img :src="require('@/assets/icons/arrow-down.svg')" alt="Officine Universelle Buly">

      </v-frame>
    </div>

    <div class="introduction__languages">
      <v-option
        class="introduction__language"
        v-for="(messages, locale) in $i18n.messages"
        v-touch:tap="() => setLocale(locale)"
        :key="locale"
      >
        {{ messages.localeName }}
      </v-option>
    </div>
  </div>
</template>

<style lang="sass">
.introduction
  width: 100%
  height: 100vh
  height: calc(var(--vh, 1vh) * 100)
  overflow: auto
  scroll-snap-type: y mandatory

  &__splash
    width: 100%
    height: 100vh
    height: calc(var(--vh, 1vh) * 100)
    scroll-snap-align: start

  &__image
    width: 100%
    height: 100%
    object-fit: contain
    display: block
    position: absolute
    z-index: -1

  &__frame
    width: 100%
    height: 100%
    transform: translateY(100vh)
    text:
      align: center
      transform: uppercase

  &__title
    color: var(--red)
    font:
      family: var(--font-family-title)
      size: var(--font-size-large)
    margin-top: auto

  &__subtitle
    font:
      family: var(--font-family-subtitle)
      size: var(--font-size-medium)

  &__subtitle-top
    letter-spacing: .7em
    padding-left: .7em

  &__subtitle-bottom
    letter-spacing: .5em
    padding-left: .54em

  &__word
    transform: translateY(100vh)
    display: inline-block

  &__cta
    font:
      family: var(--font-family-label)
      size: var(--font-size-tiny)
      weight: var(--font-weight-light)
    margin-top: auto
    line-height: 2
    transform: translateY(100vh)
    letter-spacing: var(--font-spacing-label)

  &__languages
    background:
      image: url('../assets/images/portrait-2.jpg')
      position: center center
      size: cover
    min-height: 100vh
    min-height: calc(var(--vh, 1vh) * 100)
    scroll-snap-align: start
    display: flex
    flex-direction: column

  &__language
    flex-grow: 1

  @media screen and (max-width: 600px)
    &__languages
      background-position: -60px center
    &__logo
      width: 100%
</style>

<script>
import VFrame from '@/components/VFrame.vue'
import VOption from '@/components/VOption.vue'
import api from '@/api'
import { gsap } from 'gsap'

export default {
  name: 'Introduction',

  components: {
    VFrame,
    VOption
  },

  data () {
    return {
      touch: 'ontouchstart' in window
    }
  },

  mounted () {
    this.animate()
  },

  methods: {
    addKlaviyo (locale) {
      let id
      if (locale === 'jp') id = 'ULLPTg'
      else id = 'UBN4LK'
      const s = document.createElement('script')
      s.type = 'text/javascript'
      s.async = true
      s.src = 'https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=' + id
      const f = document.getElementsByTagName('script')[0]
      f.parentNode.insertBefore(s, f)
    },
    async setLocale (locale) {
      this.addKlaviyo(locale)
      this.$store.commit('setLoading', true)
      await this.$i18n.loadLocale(locale)
      await Promise.all([
        this.$te('questions.A') &&
        api.preloadQuestionImage(this.$t('questions.A')),

        this.$te('answers.A') &&
        api.preloadAnswerImages(this.$t('answers.A'))
      ])
      this.$store.commit('setLoading', false)
      this.$router.push({ name: 'start' })

      this.$ga.send(
        'page_view',
        {
          website_language: locale.toUpperCase(),
          page_type: 'Skin Concierge',
          page_top_category: 'Skin Concierge'
        }
      )

      this.$ga.send(
        'skin_concierge',
        {
          skin_concierge_step: 'language',
          skin_concierge_choice: locale
        }
      )
    },

    animate () {
      gsap
        .timeline()
        .fromTo(this.$refs.frame.$el, {
          y: '100vh'
        }, {
          y: 0,
          ease: 'power4.out',
          duration: 1.5
        }, 0.3)
        .fromTo([
          ...this.$el.querySelectorAll('.introduction__word'),
          this.$refs.cta
        ], {
          y: '100vh'
        }, {
          y: 0,
          ease: 'power4.out',
          duration: 1.8,
          stagger: 0.14
        }, 0.2)
    }
  }
}
</script>
