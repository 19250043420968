import gql from 'graphql-tag'
import { ApolloClient } from 'apollo-client'
import { HttpLink } from 'apollo-link-http'
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'
import { ApolloLink, concat } from 'apollo-link'
import { onError } from 'apollo-link-error'

import introspectionResult from './fragmentTypes.json'

// const DISABLED = ['E4']
const DISABLED = []

class API {
  constructor (uri, token, site) {
    const httpLink = new HttpLink({ uri })

    const authenticationLink = new ApolloLink((operation, forward) => {
      operation.setContext({
        headers: {
          Authorization: `Bearer ${token}`
        }
      })

      return forward(operation)
    })

    const errorLink = onError((error) => {
      if (error.graphQLErrors) {
      }

      error.forward(error.operation)
    })

    this.lang = 'en'
    this.site = site

    this.client = new ApolloClient({
      link: concat(authenticationLink, httpLink.concat(errorLink)),
      cache: new InMemoryCache({
        fragmentMatcher: new IntrospectionFragmentMatcher({
          introspectionQueryResultData: introspectionResult
        })
      }),
      watchQuery: {
        fetchPolicy: 'network-only',
        errorPolicy: 'ignore'
      },
      query: {
        fetchPolicy: 'network-only',
        errorPolicy: 'all'
      }
    })
  }

  get localizedSite () {
    return `${this.site}_${this.lang}`
  }

  preloadQuestionImage (question) {
    return fetch(question.bulyImage)
  }

  preloadAnswerImages (answers) {
    return Promise.all((Array.isArray(answers)
      ? answers
      : Object.values(answers)
    ).map(answer => fetch(answer.bulyImage)))
  }

  fetch (lang = this.lang) {
    this.lang = lang

    return Promise
      .all([
        this.fetchQuestions(),
        this.fetchAnswers(),
        this.fetchProducts(),
        this.fetchTips(),
        this.fetchHomePage(),
        this.fetchEndPage(),
        this.fetchStartPage()
      ])
      .then(([
        questions,
        answers,
        products,
        tips,
        homePage,
        endPage,
        startPage
      ]) => ({
        questions: questions.reduce((questions, question) => {
          questions[question.bulyExternalid || 'extra'] = question
          return questions
        }, {}),
        answers: answers.reduce((answers, answer, _, blou) => {
          const questionId = answer.bulyExternalid[0] || 'extra'
          // const answerPosition = answer.bulyExternalid.slice(1) - 1

          if (!answers[questionId]) {
            // answers[questionId] = {}
            answers[questionId] = []
          }

          // answers[questionId][answerPosition] = answer
          answers[questionId].push(answer)

          return answers
        }, {}),
        products,
        tips,
        homePage,
        endPage,
        startPage
      }))
      .then(data => Promise.all([
        ...Object.values(data.questions).reduce((promises, question) => {
          const image = question.bulyImage[0]

          if (image) {
            question.bulyImage = image.url
            // promises.push(this
            //   .fetchImage({ id: image.id, width: 600 })
            //   .then((url) => { question.bulyImage = url })
            // )
          } else {
            question.bulyImage = undefined
          }

          return promises
        }, []),
        ...Object.values(data.answers).reduce((promises, answers) => {
          Object.values(answers).forEach((answer) => {
            const image = answer.bulyImage[0]

            if (image) {
              answer.bulyImage = image.url
              // promises.push(this
              //   .fetchImage({ id: image.id, width: 600 })
              //   .then((url) => { answer.bulyImage = url })
              // )
            } else {
              answer.bulyImage = undefined
            }
          })

          return promises
        }, []),
        ...Object.values(data.products).reduce((promises, product) => {
          const image = product.bulyImage[0]

          if (image) {
            product.bulyImage = image.url
            // promises.push(this
            //   .fetchImage({ id: image.id, width: 600 })
            //   .then((url) => { product.bulyImage = url })
            // )
          } else {
            product.bulyImage = undefined
          }

          return promises
        }, [])
      ]).then(() => data))
  }

  fetchHomePage () {
    return this.client
      .query({
        query: gql`
          query fetchHomePage {
            entry(type: "bulyHomePage", site: "${this.localizedSite}") {
              ... on bulyHomePage_bulyHomePage_Entry {
                titre
                sousTitre
                bulyCta
                BulyCtaMobile
              }
            }
          }
        `
      })
      .then(response => response.data.entry)
      .catch(error => console.error('Error catching global params', error))
  }

  fetchStartPage () {
    return this.client
      .query({
        query: gql`
          query fetchStartPage {
            entry(type: "bulyStartPage", site: "${this.localizedSite}") {
              ... on bulyStartPage_bulyStartPage_Entry {
                titre
                bulyCta
                bulyDescription {
                  description
                }
              }
            }
          }
        `
      })
      .then(response => response.data.entry)
      .catch(error => console.error('Error catching global params', error))
  }

  fetchEndPage () {
    return this.client
      .query({
        query: gql`
          query fetchEndPage {
            entry(type: "bulyEndPageAd", site: "${this.localizedSite}") {
              ... on bulyEndPageAd_bulyEndPageAd_Entry {
                title
                bulyImage {
                  url
                  id
                }
                bulyUrl
              }
            }
          }
        `
      })
      .then(response => response.data.entry)
      .catch(error => console.error('Error catching global params', error))
  }

  fetchAnswers () {
    return this.client
      .query({
        query: gql`
          query fetchAnswers {
            entries (type: "bulyAnswer", site: "${this.localizedSite}", section: "bulyAnswer", orderBy: "bulyExternalid") {
              ... on bulyAnswer_bulyAnswer_Entry {
                id
                title
                bulyExternalid
                bulyGenderCondition
                bulyConcern
                bulySkinProblemCategory1
                bulySkinProblemCategory2
                bulySkinProblemCategory3
                bulyImage {
                  url
                }
                analyticSlug
              }
            }
          }
        `
      })
      .then(response => response.data.entries)
      .catch(error => console.error('Error catching global params', error))
  }

  fetchQuestions () {
    return this.client
      .query({
        query: gql`
          query fetchQuestions {
            entries (type: "bulyQuestion", site: "${this.localizedSite}", section: "bulyQuestion") {
              ... on bulyQuestion_bulyQuestion_Entry {
                id
                title
                bulyImage {
                  url
                }
                description
                bulyExternalid
                bulyNoTextAnswer
                bulyIsimagefull
                bulyGradient
                bulyVideo {
                  id
                }
                analyticSlug
              }
            }
          }
        `
      })
      .then(response => response.data.entries)
      .catch(error => console.error('Error catching global params', error))
  }

  fetchProducts () {
    return this.client
      .query({
        query: gql`
          query fetchProducts {
            entries (type: "bulyProduct", site: "${this.localizedSite}", section: "bulyProduct") {
              ... on bulyProduct_bulyProduct_Entry {
                id
                title
                bulyImage {
                  url
                }
                description
                bulyHowToUse
                bulyShopifyProductId
                bulyCat
                bulyUse
                bulyGender
                bulyClimate1
                bulyClimate2
                bulyAge
                BulyBodyPart
                bulySkinType
                bulySkinProblemCategory
                bulyCareTag
              }
            }
          }
        `
      })
      .then(response => response.data.entries)
      .catch(error => console.error('Error catching global params', error))
  }

  fetchTips () {
    return this.client
      .query({
        query: gql`
          query fetchTips {
            entries (type: "bulyTip", site: "${this.localizedSite}") {
              ... on bulyTip_bulyTip_Entry {
                id
                description
                bulyExternalid
              }
            }
          }
        `
      })
      .then(response => response.data.entries)
      .catch(error => console.error('Error catching global params', error))
  }

  fetchImage ({ id, width = undefined, height = undefined }) {
    let query

    if (width && height) {
      query = {
        variables: { id, width, height },
        query: gql`
          query fetchImg ($id: [QueryArgument], $width: Int, $height: Int) {
            asset(id: $id) {
              ... on bulyContent_Asset {
                url(width: $width, immediately: true, height: $height)
              }
            }
          }
        `
      }
    } else if (width) {
      query = {
        variables: { id, width },
        query: gql`
          query fetchImg ($id: [QueryArgument], $width: Int) {
            asset(id: $id) {
              ... on bulyContent_Asset {
                url(width: $width, immediately: true)
              }
            }
          }
        `
      }
    } else if (height) {
      query = {
        variables: { id, height },
        query: gql`
          query fetchImg ($id: [QueryArgument], $height: Int) {
            asset(id: $id) {
              ... on bulyContent_Asset {
                url(height: $height, immediately: true)
              }
            }
          }
        `
      }
    } else {
      query = {
        variables: { id },
        query: gql`
          query fetchImg ($id: [QueryArgument])  {
            asset(id: $id) {
              ... on bulyContent_Asset {
                url
              }
            }
          }
        `
      }
    }

    return this.client
      .query(query)
      .then(response => response.data.asset.url)
      .catch(error => console.error('Error catching global params', error))
  }

  // answerIds: answers
  // items: stuffs to filter
  // properties: properties to filter on
  filter (answerIds, items, properties) {
    items = items.filter(item => !DISABLED.includes(item.bulyExternalid))

    answerIds = this.decode(answerIds)

    if (!answerIds.length) {
      return items
    }

    const result = items.filter(item => properties.every(property => (
      !item[property] || this.match(answerIds, item[property])
    )))

    return result
  }

  match (idsA, idsB) {
    idsA = this.decode(idsA)
    idsB = this.decode(idsB)

    return idsB.some((id) => idsA.includes(id))
  }

  decode (ids) {
    if (Array.isArray(ids)) {
      return ids
    }

    return ids.split(';').reduce((ids, range) => {
      const [from, to = from] = range.split(/[-~⁓]/)
      const letter = from[0]
      let minimum = parseInt(from.slice(1), 10)
      let maximum = parseInt(to.slice(1), 10)

      if (minimum > maximum) {
        const temporary = maximum
        maximum = minimum
        minimum = temporary
      }

      for (var n = minimum; n <= maximum; n++) {
        ids.push(`${letter}${n}`)
      }

      return ids
    }, [])
  }
}

export default new API(
  process.env.VUE_APP_CRAFT_API_URI,
  process.env.VUE_APP_CRAFT_API_TOKEN,
  process.env.VUE_APP_CRAFT_API_SITE
)
