<template>
  <div class="result-splash">
    <v-button
      class="result-splash__cta"
      light
      v-touch:tap="seeResults"
    >
      {{ $t('result.cta') }}
    </v-button>
  </div>
</template>

<style lang="sass">
.result-splash
  background:
    image: url('/img/illustrations/result.jpg')
    size: cover
    position: center center
  width: 100%
  height: 100vh
  height: calc(var(--vh, 1vh) * 100)
  overflow: auto
  display: flex
  flex-direction: column

  &__cta
    margin: auto
    max-width: 500px
</style>

<script>
import VButton from '@/components/VButton'

export default {
  name: 'ResultSplash',

  components: {
    VButton
  },

  methods: {
    seeResults () {
      this.$ga.send(
        'skin_concierge',
        {
          skin_concierge_action: 'view result'
        }
      )
      this.$router.push('mail-form')
    }
  }
}
</script>
