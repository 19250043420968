<template>
    <div class="v-slider">
      <transition name="fade">
        <button v-show="!isMinScroll" class="v-slider__scroll_button" @click="() => scroll('left', 1)">
          <img :src="require('@/assets/icons/arrow-down.svg')" alt="<">
        </button>
      </transition>
      <div @scroll="onScroll" class="v-slider__scroller" ref="scroller">
          <slot></slot>
      </div>
      <transition name="fade">
        <button v-show="!isMaxScroll" class="v-slider__scroll_button" @click="() => scroll('right', 1)">
          <img :src="require('@/assets/icons/arrow-down.svg')" alt=">">
        </button>
      </transition>
    </div>
</template>

<style lang="sass">
.v-slider
  position: relative
  display: flex
  &__scroller
    overflow-x: scroll
    scroll-snap-type: x mandatory
    height: 100%
    // width: 100vw
    position: relative
    padding: 0
    margin: 0
    display: flex
    justify-content: flex-start
    align-items: stretch
    scroll-behavior: smooth
    -webkit-overflow-scrolling: touch
    scroll-padding-left: 35px
    &::-webkit-scrollbar
      display: none

  &__scroll_button
    position: absolute
    // top: 50%
    background: transparent
    border: none
    cursor: pointer
    z-index: 2
    height: 100%
    width: 90px
    transition: opacity 0.3s ease
    opacity: 1
    will-change: opacity
    &:hover
      opacity: 0.5
    &:first-of-type
      text-align: left
      left: 0
      background-image: linear-gradient(to right, #fff, transparent)
      img
        transform: rotate(90deg)
    &:last-of-type
      text-align: right
      right: 0
      background-image: linear-gradient(to left, #fff, transparent)
      img
        transform: rotate(-90deg)

  @media screen and (max-width: 500px)
    &__scroller
      scroll-padding-left: 15px
    &__scroll_button
      background-image: none !important

.fade-enter-active, .fade-leave-active
  transition: opacity .3s
.fade-enter, .fade-leave-to
  opacity: 0
</style>

<script>
export default {
  data () {
    return {
      isMinScroll: true,
      isMaxScroll: false
    }
  },
  mounted () {
    this.onResize()
    addEventListener('resize', this.onResize)
  },
  methods: {
    scroll (direction) {
      const distance = direction === 'left' ? -this.scrollDistance : this.scrollDistance
      this.$refs.scroller.scrollBy(distance, 0)
    },
    onScroll () {
      if (this.$refs.scroller.scrollLeft <= 25) {
        this.isMinScroll = true
        this.isMaxScroll = false
      } else if (this.$refs.scroller.scrollLeft >= this.$refs.scroller.scrollWidth - this.$refs.scroller.clientWidth) {
        this.isMinScroll = false
        this.isMaxScroll = true
      } else {
        this.isMinScroll = false
        this.isMaxScroll = false
      }
    },
    onResize () {
      const productsToScroll = 1
      const margin = 35
      this.scrollDistance = (this.$el.querySelector('.v-slider__item').offsetWidth + margin) * productsToScroll
    }
  }
}

</script>
