import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loading: false,
    answers: [
      // 'A2',
      // 'B4',
      // 'C2',
      // 'D5',
      // 'E4',
      // 'F4',
      // 'G4',
      // 'H11',
      // 'I3',
      // 'J4',
      // 'K7',
      // 'L13',
      // 'M12',
      // 'N12'
    ]
  },
  getters: {
    answer: state => questionID => state.answers.find(answerID => (
      answerID[0] === questionID
    ))
  },
  mutations: {
    setLoading (state, loading) {
      state.loading = loading
    },

    selectAnswer (state, answerID) {
      // WE GET THE FIRST CAR AND
      // USE IT AS THE QUESTION'S ID

      const questionID = answerID[0]
      // We check if answer alreay exists or not
      const answers = state.answers.filter(answerID => (
        answerID[0] !== questionID
      ))

      answers.push(answerID)
      state.answers = answers
    },
    reinitAnswers (state) {
      state.answers.splice(0, state.answers.length)
    }
  },
  actions: {

  },
  modules: {

  }
})
