<template>
  <div class="app">
    <v-symbols/>
    <transition
      name="page"
      @enter="pageTransition"
      @after-enter="afterPageEnter"
      @before-leave="beforePageLeave"
      @leave="pageTransition"
      @after-leave="afterPageLeave"
    >
      <router-view
        v-if="initialized"
        :key="$route.fullPath"
      />
    </transition>

    <transition>
      <div
        class="app__loading"
        :class="{
          'app__loading--initializing': !initialized
        }"
        v-if="$store.state.loading"
      >
        {{$t('loading')}}...
      </div>
    </transition>
  </div>
</template>

<style lang="sass">
.app
  &__loading
    top: 0
    left: 0
    z-index: 1000
    position: fixed
    width: 100vw
    height: 100vh
    height: calc(var(--vh, 1vh) * 100)
    display: flex
    align-items: center
    justify-content: center
    color: var(--white)
    font:
      family: var(--font-family-label)
      size: var(--font-size-small)
      weight: var(--font-weight-light)
    text-transform: uppercase

    &::before
      content: ''
      z-index: -1
      background-color: var(--black)
      width: 100%
      height: 100%
      top: 0
      left: 0
      opacity: .6
      position: absolute

    &--initializing::before
      opacity: 1

    &.v-enter-active, &.v-leave-active
      transition: 1s

    &.v-enter, &.v-leave-to
      opacity: 0

.page-enter-active, .page-leave-active
  transition: 2s var(--ease-in-out-quart)
  min-height: 100vh
  min-height: calc(var(--vh, 1vh) * 100)
</style>

<script>
import VSymbols from '@/components/VSymbols.vue'

export default {
  name: 'App',

  created () {
    this.pageHeight = 0
    this.updateViewportHeight = this.updateViewportHeight.bind(this)
    this.disableScroll = this.disableScroll.bind(this)
    this.$store.commit('setLoading', true)
  },

  mounted () {
    this.updateViewportHeight()
    addEventListener('resize', this.updateViewportHeight)

    Promise.all([
      this.$audio.load(
        '/sounds/ambiance.mp3',
        '/sounds/click.mp3',
        '/sounds/ding.mp3',
        '/sounds/result.mp3'
      ),
      this.$i18n.loadLocale()
    ]).then(() => {
      this.$audio.play('/sounds/ambiance.mp3', { loop: true })
      this.$store.commit('setLoading', false)
      this.initialized = true
    })
  },

  beforeDestroy () {
    removeEventListener('resize', this.updateViewportHeight)
  },

  data () {
    return {
      initialized: false
    }
  },

  methods: {
    pageTransition (el) {
      addEventListener('wheel', this.disableScroll, { passive: false })
      document.body.style.touchAction =
      document.body.style.pointerEvents = 'none'
      el.style.transform = `translateY(${
        document.documentElement.scrollTop - this.pageHeight
      }px)`
    },

    afterPageLeave (el) {
      el.style.transform = ''
      document.documentElement.scrollTop = 0
    },

    afterPageEnter (el) {
      this.afterPageLeave(el)
      removeEventListener('wheel', this.disableScroll)
      document.body.style.touchAction =
      document.body.style.pointerEvents = ''
    },

    beforePageLeave (el) {
      this.pageHeight = Math.max(window.innerHeight, el.offsetHeight)
    },

    updateViewportHeight () {
      document.documentElement.style.setProperty('--vh', `${
        window.innerHeight * 0.01
      }px`)
    },

    disableScroll (event) {
      event.preventDefault()
      event.stopPropagation()
    }
  },
  components: {
    VSymbols
  }
}
</script>
